<template>
    <div>
        <!--        <myBreadcrumb :nav="nav"></myBreadcrumb>-->
        <el-carousel height="464px">
            <el-carousel-item v-for="(item, index) in slideshowList" :key="index">
                <img class="lbt" :src="item.picUrl" alt="" />
            </el-carousel-item>
        </el-carousel>
        <div class="filter">
            <div class="item">
                <div class="title">类型：</div>
                <el-select v-model="type" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="title">排序：</div>
                <el-select v-model="type1" placeholder="请选择">
                    <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
        </div>

        <div class="n">
            <div class="book" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/Details', query: { id: item.id } })">
                <img :src="item.picUrl" alt="" />
                <span>{{ item.name }}</span>
            </div>
        </div>
        <el-pagination @size-change="getHeritageActivityList" @current-change="getHeritageActivityList" :current-page.sync="page" :page-sizes="[12, 24, 36, 48]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
import { getSlideshowList } from '@/api';
export default {
    data() {
        return {
            tabs: 0,
            limit: 12,
            page: 1,
            total: 10,
            list: [],
            nav: [
                {
                    title: '首页',
                    to: '/',
                },
                {
                    title: '推荐资源',
                },
                {
                    title: '学才艺',
                },
            ],
            slideshowList: [],
            options: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    label: '红色足迹',
                    value: 1,
                },
                {
                    label: '艺术世界',
                    value: 2,
                },
                {
                    label: '名家讲堂',
                    value: 3,
                },
            ],
            options1: [
                {
                    value: '',
                    label: '默认',
                },
                {
                    label: '热门',
                    value: 1,
                },
                {
                    label: '最新发布',
                    value: 2,
                },
            ],
            type: '',
            type1: '',
        };
    },
    created() {
        this.getHeritageActivityList();
        getSlideshowList().then((res) => {
            console.log('轮播图', res);
            this.slideshowList = res.rows;
        });
    },
    watch: {
        type() {
            this.page = 1;
            this.list = [];
            this.getHeritageActivityList();
        },
        type1() {
            this.page = 1;
            this.list = [];
            this.getHeritageActivityList();
        },
    },
    methods: {
        getHeritageActivityList() {
            let params = {
                pageNum: this.page,
                pageSize: this.limit,
                type: 2,
                libraryId: localStorage.getItem('libraryId'),
            };
            if (this.type !== '') {
                params.bookType = this.type;
            }
            if (this.type1 !== '') {
                params.sortBy = this.type1;
            }
            this.$hao.getRecommendResource(params).then((res) => {
                console.log('学才艺', res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.filter {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .item {
        display: flex;
        align-items: center;

        .title {
            display: flex;
            width: max-content;
        }

        .el-select {
            width: 160px;
            margin-right: 24px;

            .el-input__inner {
                border: 1px solid #e4e7ed;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 14px;
                line-height: 17px;
                color: #606266;
            }
        }
    }
}
.position {
    margin-top: 24px;

    span {
        font-size: 16px;
        line-height: 23px;

        color: #666666;
    }
}

// .h {
//     margin-top: 24px;
//     display: flex;
// }
.h {
    margin-top: 24px;
    display: flex;
    margin-bottom: 24px;

    .types {
        display: flex;
        align-items: center;
        margin-right: 24px;

        span {
            font-size: 13px;
            line-height: 19px;
        }

        .el-select {
            width: 160px;
            height: 39px;
            margin-left: 10px;

            border-radius: 4px;
        }
    }
}
.right {
    display: flex;
    align-items: center;
    //margin-left: 49%;
    width: 100%;
    justify-content: flex-end;
    ::v-deep .el-input__inner,
    ::v-deep .el-input {
        border-radius: 20px;
        width: 240px;
        height: 30px;
    }
    ::v-deep .el-input__prefix {
        margin-top: -3px;
        //i {
        //    height: 30px;
        //}
    }
}
.menu {
    width: 20%;

    span {
        size: 14px;
        height: 19px;
        color: #333333;
        margin-right: 10px;
    }
}

select {
    width: 70%;
    border: 1px solid rgba(0, 0, 0, 0.06);
    height: 39px;
    outline: none;
    padding-left: 12px;
}

.p {
    padding-right: 100px;
}

.box {
    width: 20%;
    margin-left: 39%;

    input {
        border: 1px solid rgba(237, 162, 0, 0.1);
        border-radius: 16px;
        background-color: #ffffff;
        width: 100%;
        padding: 5.5px 0 5.5px 0;
        outline: none;
    }
}

.n {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    //margin-right: 1%;

    .book {
        display: flex;
        width: 15%;
        flex-direction: column;
        align-items: center;
        margin-right: 2%;

        img {
            width: 100%;
            height: 253.5px;
            margin-bottom: 24px;
            border-radius: 2px;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
        }

        span {
            font-size: 18px;
            color: #333333;
            margin-bottom: 50.5px;
        }
    }
}

.book:nth-child(6n) {
    margin-right: 0;
}

.lbt {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 24px 0;
}
.el-carousel {
    // width: 500px;
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }
    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    /deep/ .el-carousel__indicators {
        // 指示器
        // left: unset;
        transform: unset;
        // right: 2%;
    }
    /deep/ .el-carousel__button {
        // 指示器按钮
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
    }
    /deep/ .is-active .el-carousel__button {
        // 指示器激活按钮
        background: #ffffff;
    }
}
</style>
